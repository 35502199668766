:root {
  --blanc: #fff;
  --bleu-2: #010517;
  --bleu-3: #2e71e5;
  --bleu-4: #14246a;
  --bleu-5: #060e31;
  --texte-regular-bold-font-family: "NunitoSans-ExtraBold", Helvetica;
  --texte-regular-bold-font-size: 16px;
  --texte-regular-bold-font-style: normal;
  --texte-regular-bold-font-weight: 800;
  --texte-regular-bold-letter-spacing: 0px;
  --texte-regular-bold-line-height: 160.0%;
  --texte-regular-font-family: "NunitoSans-Regular", Helvetica;
  --texte-regular-font-size: 16px;
  --texte-regular-font-style: normal;
  --texte-regular-font-weight: 400;
  --texte-regular-letter-spacing: 0px;
  --texte-regular-line-height: 160.0%;
  --titre-h2-font-family: "NunitoSans-ExtraBold", Helvetica;
  --titre-h2-font-size: 46px;
  --titre-h2-font-style: normal;
  --titre-h2-font-weight: 800;
  --titre-h2-letter-spacing: 0px;
  --titre-h2-line-height: 160.0%;
  --titre-h3-font-family: "NunitoSans-Bold", Helvetica;
  --titre-h3-font-size: 36px;
  --titre-h3-font-style: normal;
  --titre-h3-font-weight: 700;
  --titre-h3-letter-spacing: 0px;
  --titre-h3-line-height: 160.0%;
  --titre-h4-font-family: "NunitoSans-ExtraBold", Helvetica;
  --titre-h4-font-size: 20px;
  --titre-h4-font-style: normal;
  --titre-h4-font-weight: 800;
  --titre-h4-letter-spacing: 0px;
  --titre-h4-line-height: 160.0%;
}
/*# sourceMappingURL=index.d4db86aa.css.map */
