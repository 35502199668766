:root {
  --blanc: rgba(255, 255, 255, 1);
  --bleu-2: rgba(1, 5, 23, 1);
  --bleu-3: rgba(46, 113, 229, 1);
  --bleu-4: rgba(20, 36, 106, 1);
  --bleu-5: rgba(6, 14, 49, 1);
  --texte-regular-bold-font-family: "NunitoSans-ExtraBold", Helvetica;
  --texte-regular-bold-font-size: 16px;
  --texte-regular-bold-font-style: normal;
  --texte-regular-bold-font-weight: 800;
  --texte-regular-bold-letter-spacing: 0px;
  --texte-regular-bold-line-height: 160.0000023841858%;
  --texte-regular-font-family: "NunitoSans-Regular", Helvetica;
  --texte-regular-font-size: 16px;
  --texte-regular-font-style: normal;
  --texte-regular-font-weight: 400;
  --texte-regular-letter-spacing: 0px;
  --texte-regular-line-height: 160.0000023841858%;
  --titre-h2-font-family: "NunitoSans-ExtraBold", Helvetica;
  --titre-h2-font-size: 46px;
  --titre-h2-font-style: normal;
  --titre-h2-font-weight: 800;
  --titre-h2-letter-spacing: 0px;
  --titre-h2-line-height: 160.0000023841858%;
  --titre-h3-font-family: "NunitoSans-Bold", Helvetica;
  --titre-h3-font-size: 36px;
  --titre-h3-font-style: normal;
  --titre-h3-font-weight: 700;
  --titre-h3-letter-spacing: 0px;
  --titre-h3-line-height: 160.0000023841858%;
  --titre-h4-font-family: "NunitoSans-ExtraBold", Helvetica;
  --titre-h4-font-size: 20px;
  --titre-h4-font-style: normal;
  --titre-h4-font-weight: 800;
  --titre-h4-letter-spacing: 0px;
  --titre-h4-line-height: 160.0000023841858%;
}
